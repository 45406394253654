import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useContext } from "react";
import { WishlistContext } from "../context/wishlist-context";
import { LanguageContext } from "../context/language-context";
import t from "../resources/translations";

const CTA = ({ data }) => {
  const { setContattaci } = useContext(WishlistContext);
  const l = useContext(LanguageContext).locale;
  return (
    <div className="is-card grid my-20 md:my-24 overflow-hidden">
      <StaticImage
        style={{
          gridArea: "1/1",
        }}
        className="md:hidden"
        layout="fullWidth"
        placeholder="blurred"
        formats={["webp", "auto"]}
        alt=""
        src={"../resources/images/cta-mob.jpg"}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
        }}
        className="hidden md:block"
        layout="fullWidth"
        placeholder="blurred"
        formats={["webp", "auto"]}
        alt=""
        src={"../resources/images/cta.jpg"}
      />
      <div
        style={{
          gridArea: "1/1",
        }}
        className="relative z-10"
      >
        <h2 className="is-h1m md:is-h1 text-white text-center pt-14">
          {data?.title ?? t.defaultCtaTitolo[l]}
        </h2>
        <div className="absolute my-5 md:bottom-9 w-full text-center">
          {data?.link ? (
            <Link to={data.link} className="is-button md:is-button-sec">
              {data?.text ?? t.defaultCtaBottone[l]}
            </Link>
          ) : (
            <button
              className="is-button md:is-button-sec"
              onClick={() => {
                setContattaci(true);
              }}
            >
              {data?.text ?? t.defaultCtaBottone[l]}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

CTA.defaultProps = {
  data: { title: null, link: null, text: null },
};

export default CTA;

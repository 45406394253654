import React, { useContext } from "react";
import { Link } from "gatsby";
import BreadCrumb from "../layout/breadcrumb";
import Layout from "../layout/layout";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import CTA from "../cta";
import IntroCard from "../storyblok-components/intro-card";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";

const About = ({ data, intro, location }) => {
  const about1 = getImage(data.About1);
  const about2 = getImage(data.About2);
  const team = [
    {
      name: "Francesco Cerusco",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
      img: about1,
    },
    {
      name: "Francesco 2",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
      img: about2,
    },
    {
      name: "Francesco L'altro",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
      img: about2,
    },
    {
      name: "Francescone",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
      img: about1,
    },
  ];

  const l = useContext(LanguageContext).locale;

  return (
    <Layout location={location}>
      <BreadCrumb>
        <Link to="/">Home</Link>
        <p className="font-bold">{t.about[l]}</p>
      </BreadCrumb>
      <h1 className="md:is-h1 is-h1m text-white mb-4">{t.about[l]}</h1>
      <IntroCard mobmb="700px">{intro}</IntroCard>
      {/* <div className="flex flex-wrap gap-5 md:gap-11 md:mt-9">
        {team.map((member) => (
          <Member data={member} key={member.name} />
        ))}
      </div> */}
      <CTA />
    </Layout>
  );
};

const Member = ({ data }) => {
  return (
    <div className="is-card text-white w-full md:w-1/3 flex-grow md:flex md:items-center">
      <GatsbyImage image={data.img} alt="" className="md:w-1/3 order-4" />
      <div className="p-5 md:w-2/3 md:pt-10 md:pb-4 md:px-16">
        <h3 className="text-is-blue is-h3b mb-3.5">{data.name}</h3>
        <p>{data.desc}</p>
        <div className="is-comm-flex mt-2 md:mt-10">
          <Link to="" className="is-button">
            Contattami!
          </Link>
          <div className="flex gap-3">
            <Link to="">
              <StaticImage
                src="../../resources/images/wa-white.svg"
                placeholder="tracedSVG"
                alt="contattami su whatsapp"
              />
            </Link>
            <Link to="">
              <StaticImage
                src="../../resources/images/viber-white.svg"
                placeholder="tracedSVG"
                alt="contattami su viber"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import AboutBgMob from "../../resources/images/about-bg-mob.png";
import AboutBg from "../../resources/images/about-bg.jpg";
import useWindowSize from "../../lib/use-window-size";

const IntroCard = ({ children, mobmb }) => {
  const { isMobile } = useWindowSize();
  return (
    <div
      className="is-card bg-right bg-no-repeat bg-cover overflow-visible px-4 w-screen -ml-3.5 md:ml-0 md:w-auto md:py-7 md:px-14"
      style={
        isMobile
          ? {
              backgroundImage: `url(${AboutBgMob})`,
              height: "416px",
              marginBottom: mobmb,
              paddingTop: "166px",
            }
          : {
              backgroundImage: `url(${AboutBg})`,
              height: "auto",
              marginBottom: 0,
              paddingTop: "28px",
            }
      }
    >
      <div className="is-card is-p text-white p-8 md:w-2/5">{children}</div>
    </div>
  );
};

IntroCard.defaultProps = {
  mobmb: "300px",
};

export default IntroCard;

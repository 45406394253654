import { graphql } from "gatsby";
import About from "../../components/pages/about";
import React from "react";
import Seo from "../../components/layout/seo";

const intro = (
  <>
    <h2 className="is-h3b text-is-blue">About us</h2>
    <p className="py-6">
      International Saws, a reference brand in the sector, has a new corporate
      structure since 2015 to pursue new development plans at national and
      international level.
      <br />
      <br />
      The new company, founded and led by Giorgio Lamolinara, has its registered
      office and operational headquarters in Roseto degli Abruzzi (TE), in order
      to guarantee a timely shipping service at a logistical level.
    </p>
    <h2 className="is-h3b text-is-blue mt-6">What we do</h2>
    <p className="py-6">
      Continuous research into surface treatments, for a long service life even
      in extreme conditions of use of blades and tools.
      <br />
      <br />
      Noise reduction for greater comfort in prolonged use.
      <br />
      <br />
      Development of new technological solutions to meet the needs of a changing
      market.
    </p>
  </>
);

const AboutUs = ({ data, location }) => (
  <>
    <Seo title="About Us" desc="" />
    <About data={data} intro={intro} location={location} />
  </>
);

export const query = graphql`
  query aboutQueryEng {
    About1: file(relativePath: { eq: "about1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    About2: file(relativePath: { eq: "about2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default AboutUs;
